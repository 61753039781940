<template>
  <div>
    <terms :data="getValue()" :saveData="function(ret) { setDescription(ret) }" />
    <transition name="flixFadeIn">
      <div :key="success" v-if="success" class="flix-alert flix-alert-success">{{ $t('message.successfullSaved') }}</div>
    </transition>

    <formRadio v-if="form[index].subtype !== 'email'" :data="{value: form[index].required, values: [{label: 'Pflichtfeld', value: true},{label: 'kein Pflichtfeld', value: false}]}" :callback="setRequired"/>
  </div>
</template>
<script>
export default {
  components: {
    terms () { return import('@/components/profile/terms') },
    formRadio () { return import('@/components/formBuilder/parts/radio') }
  },
  props: {
    form: Array,
    index: Number
  },
  data () {
    return {
      variables: this.$getUserVariables(),
      success: false
    }
  },
  methods: {
    getValue () {
      var agb = JSON.parse(JSON.stringify(this.form))[this.index].description

      if (!agb) {
        return this.variables.user.terms
      }
      return agb
    },
    setRequired (data) {
      this.form[this.index].required = data
    },
    setDescription (data) {
      this.form[this.index].description = data.terms
      this.success = true

      setTimeout(function () {
        this.success = false
      }.bind(this), 2000)
    },
    setSubtype (data) {
      this.form[this.index].subtype = data
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
